import PropTypes from "prop-types";
import { Section, Title, DescriptionText, SubTitle, SublistSection } from "./style.jsx";
const SectionSubListArea = ({ data }) => {
    return (
        <Section>
            {data?.headings && (
                <Title as={data?.headings[0].level}>
                    {data?.headings[0].content}
                </Title>
            )}

            {/* {data?.items &&
                data?.items.map((item, i) => (
                    <DescriptionText key={i}>{item.desc}</DescriptionText>
                ))} */}

            {
                data?.headings && (
                    <DescriptionText>
                        {data?.headings[0].description}
                    </DescriptionText>
                )
            }

            {data?.subheadings &&
                data?.subheadings.map((item, i) => (
                    <SublistSection key={i}>
                        <SubTitle>{i+1}  {"."} {item.heading}</SubTitle>
                        {
                            item?.contents.map((data,i) => (
                                <DescriptionText key={i}>{data}</DescriptionText> 
                            ))
                        }
                    </SublistSection>
                ))}
        </Section>
    );
};
SectionSubListArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                level: PropTypes.string,
                content: PropTypes.string,
                description:PropTypes.string
            })
        ),
        // items: PropTypes.arrayOf(
        //     PropTypes.shape({
        //         id: [PropTypes.string, PropTypes.number],
        //         alt: PropTypes.string,
        //         desc: PropTypes.string,
        //     })
        // ),
        subheadings: PropTypes.arrayOf(
            PropTypes.shape({
                level: PropTypes.string,
                content: PropTypes.string,
            })
        ),
    }),
};
export default SectionSubListArea;
