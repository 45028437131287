import styled, { device } from "@theme/utils";

export const Section = styled.div`
    margin-top: 30px;
`;
export const SublistSection = styled.div`
    margin-top: 30px;
    margin-left:20px;
`
export const Title = styled.h3``;
export const SubTitle = styled.h3`
    font-size:18px;
`;
export const DescriptionText = styled.p``;
